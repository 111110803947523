// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all-cards {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2vh;
  justify-content: center;
  width: 13vw;
  height: 14vh;
  border-radius: 10px;
  /* background-color: rgb(245, 178, 178); */
  box-shadow: 0px 16px 30px 0px rgba(0, 0, 0, 0.099);
}

.figma_modify {
  width: 9vw;
}
`, "",{"version":3,"sources":["webpack://./src/screen/Home/components/PlacementPartner/PartnerCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,QAAQ;EACR,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,0CAA0C;EAC1C,kDAAkD;AACpD;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".all-cards {\r\n  display: flex;\r\n  align-items: center;\r\n  flex-direction: column;\r\n  gap: 2vh;\r\n  justify-content: center;\r\n  width: 13vw;\r\n  height: 14vh;\r\n  border-radius: 10px;\r\n  /* background-color: rgb(245, 178, 178); */\r\n  box-shadow: 0px 16px 30px 0px rgba(0, 0, 0, 0.099);\r\n}\r\n\r\n.figma_modify {\r\n  width: 9vw;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
