import { Box, Container, Typography } from "@material-ui/core";
import { Pagination, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import WorkshopImage from "../../assets/images/Blog-Image/blog-image.png";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import RenderHtmlString from "components/RenderHtmlString/RenderHtmlString";

export const WorkshopData = ({ id, title, date, logo, article }) => {
  return (
    <>
      <Stack direction="column" spacing={4}>
        <Paper
          elevation={3}
          sx={{
            marginTop: "2rem",
            marginLeft: "2rem",
            padding: "1rem",
            marginRight: "2rem",
          }}
        >
          <Stack style={{ flexDirection: "row", justifyContent:"center", alignItems:"center",}} direction={{ sm: "column", md: "row" }} spacing={2}>
            <div className="workshop-image">
              <img
                src={logo}
                style={{
                  width: "30rem",
                  height: "15rem",
                  objectFit: "cover",
                  marginTop:"10px"
                }}
              />
            </div>
            <div className="workshop-information">
              <Typography variant="h6" style={{ fontSize: "25px" }}>
                {title}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  
                }}
              >
                {" "}
                <Typography variant="subtitle1" style={{ marginTop:"5px", marginBottom:"5px" }}>Date:- {date}</Typography>{" "}
              </div>

              <Typography variant="body1" className="workshop-content">
                <RenderHtmlString htmlString={article} />
              </Typography>
            </div>
          </Stack>
          <div style={{ display: "flex", justifyContent: "flex-end",marginTop:"10px" }}>
            <Link to={`/workshop-page/${id}`}>
              <Button
                variant="contained"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                Read More
              </Button>
            </Link>
          </div>
        </Paper>
      </Stack>
    </>
  );
};
